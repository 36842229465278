import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";

import { useNotification } from "../../Notification/NotificationContext";

import { NewTheme } from "../../Theme/Theme";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Php from "../../../Backend/Php";
import moment from "moment";

const php = new Php();
const height = window.innerHeight;

export default function HoldingVolumeModal(props) {
  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);
  const holdingScript = props.holdingScript;

  const footerContent = (
    <div>
      <Button
        label="Close"
        icon="pi pi-times"
        onClick={() => props.setVisible(false)}
        className="p-button-text"
        style={{ color: NewTheme.MainColor }}
      />
    </div>
  );
  return (
    <Dialog
      header={
        <div style={{ color: NewTheme.MainColor }}>{"Holding Summary"}</div>
      }
      visible={props.visible}
      position={props.position}
      style={{ width: "70vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => props.setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <DataTable
        removableSort
        stripedRows
        showGridlines
        scrollHeight={height - 200}
        scrollable
        // filters={filters}
        value={holdingScript}
        size="small"
      >
        <Column
          style={{ width: "25%" }}
          sortable
          showFilterMenu={false}
          field="segment"
          header="Segment"
        ></Column>

        <Column
          style={{ width: "25%" }}
          showFilterMenu={false}
          field="buy"
          header="Buy"
          body={(rowData) => <div>{parseFloat(rowData.buy).toFixed(0)}</div>}
        ></Column>
        <Column
          style={{ width: "25%" }}
          showFilterMenu={false}
          field="sell"
          header="Sell"
          body={(rowData) => <div>{parseFloat(rowData.sell).toFixed(0)}</div>}
        ></Column>
        <Column
          style={{ width: "25%" }}
          showFilterMenu={false}
          field="total"
          header="Total"
          body={(rowData) => <div>{parseFloat(rowData.total).toFixed(0)}</div>}
        ></Column>
      </DataTable>
    </Dialog>
  );
}
