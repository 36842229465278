import React from "react";
// import { useNotification } from "../../Notification/NotificationContext";
import Php from "../../../Backend/Php";
import { TabPanel, TabView } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { NewTheme } from "../../Theme/Theme";
import { InputText } from "primereact/inputtext";
import styled from "styled-components";
import { useNotification } from "../../Notification/NotificationContext";

const php = new Php();
const height = window.innerHeight;

const CreateClientButton = styled.button`
  background-color: ${(props) =>
    props.background ? props.background : NewTheme.MainColor};
  color: ${NewTheme.lightmain};
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s; /* Add smooth transitions */

  &:hover {
    background-color: #fff;
    color: ${(props) =>
      props.background ? props.background : NewTheme.MainColor};
    border: 1px solid
      ${(props) => (props.background ? props.background : NewTheme.MainColor)};
  }
`;

const SearchInput = styled.input`
  border: 1px solid ${NewTheme.MainColor};
  background-color: ${NewTheme.lightmain};
  color: ${NewTheme.MainColor};
  padding: 10px 10px;
  width: 100%;
  outline: none;
  border-radius: 5px;
`;

export default function Script(props) {
  const [loading, setLoading] = React.useState(false);
  const [scriptName, setfilteredValue] = React.useState("");
  const [clientScript, setClientScript] = React.useState([]);
  const [clientFutScript, setClientFutScript] = React.useState([]);
  const [clientMcxScript, setClientMcxScript] = React.useState([]);
  const [clientFoScript, setClientFoScript] = React.useState([]);
  const [clientBannedScript, setClientBannedScript] = React.useState([]);
  const { addNotification } = useNotification();

  const [filters, setFilters] = React.useState({
    symbol: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  React.useEffect(() => {
    load_users_scripts();
  }, []);

  const load_users_scripts = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        b_id: localStorage.getItem("brokersId"),
        c_id: props.data.id,
      };

      php.load_users_scripts(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setClientScript(r.script);
          /////

          if (scriptName.length < 2) {
            let fut = r.script.filter((x) => x.script_type == "fut");
            setClientFutScript(fut);
          } else {
            searchScript(scriptName);
          }
          let mcx = r.script.filter((x) => x.script_type == "mcx");
          let fo = r.script.filter((x) => x.script_type == "fo");

          setClientMcxScript(mcx);
          setClientFoScript(fo);
          setClientBannedScript(r.banned);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const searchScript = (value) => {
    var scriptName = value;
    if (scriptName.length > 2) {
      let scriptData = [];

      scriptData = clientScript.filter(
        (x) =>
          x.symbol.toLowerCase().includes(scriptName.toLowerCase()) &&
          x.script_type == "fut"
      );
      setClientFutScript(scriptData);
      setfilteredValue(scriptName);
    } else {
      let fut = clientScript.filter((x) => x.script_type == "fut");
      setClientFutScript(fut);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <TabView>
        <TabPanel style={{ color: NewTheme.MainColor }} header="FUT">
          <div className="card">
            <DataTable
              removableSort
              stripedRows
              showGridlines
              scrollHeight={height - 200}
              scrollable
              filters={filters}
              value={clientFutScript}
              size="small"
            >
              <Column
                style={{ width: "30%" }}
                sortable
                showFilterMenu={false}
                field="name"
                header="Symbol"
              ></Column>
              <Column
                style={{ width: "20%" }}
                header="Punch Qty"
                body={(rowData) => (
                  <InputText
                    style={{ width: "100%" }}
                    value={parseFloat(rowData.punch_qty).toFixed(0)}
                    disabled
                  />
                )}
                field="punch_qty"
              ></Column>
              <Column
                style={{ width: "20%" }}
                header="Total Qty"
                field="total_qty"
                body={(rowData) => (
                  <InputText
                    style={{ width: "100%" }}
                    value={parseFloat(rowData.total_qty).toFixed(0)}
                    disabled
                  />
                )}
              ></Column>
            </DataTable>
          </div>
        </TabPanel>
        <TabPanel header="MCX">
          <div className="card">
            <DataTable
              removableSort
              stripedRows
              showGridlines
              scrollHeight={height - 200}
              scrollable
              size="small"
              filters={filters}
              value={clientMcxScript}
            >
              <Column
                style={{ width: "30%" }}
                sortable
                showFilterMenu={false}
                field="name"
                header="Name"
              ></Column>
              <Column
                style={{ width: "20%" }}
                header="Punch Qty"
                body={(rowData) => (
                  <InputText
                    style={{ width: "100%" }}
                    value={parseFloat(rowData.punch_qty).toFixed(0)}
                    disabled
                  />
                )}
                field="punch_qty"
              ></Column>
              <Column
                style={{ width: "20%" }}
                header="Total Qty"
                field="total_qty"
                body={(rowData) => (
                  <InputText
                    style={{ width: "100%" }}
                    value={parseFloat(rowData.total_qty).toFixed(0)}
                    disabled
                  />
                )}
              ></Column>
            </DataTable>
          </div>
        </TabPanel>
        <TabPanel header="OPTIONS">
          <div className="card">
            <DataTable
              removableSort
              stripedRows
              showGridlines
              scrollHeight={height - 200}
              scrollable
              size="small"
              filters={filters}
              value={clientFoScript}
            >
              <Column
                sortable
                showFilterMenu={false}
                field="name"
                header="Name"
                body={(rowData) => rowData.symbol + " " + rowData.type}
              ></Column>
              <Column header="Punch Qty" field="punch_qty"></Column>
              <Column header="Total Qty" field="total_qty"></Column>
            </DataTable>
          </div>
        </TabPanel>
        <TabPanel header="BANNED">
          <div className="card">
            <DataTable
              removableSort
              stripedRows
              showGridlines
              scrollHeight={height - 200}
              scrollable
              filters={filters}
              size="small"
              value={clientBannedScript}
            >
              <Column
                sortable
                showFilterMenu={false}
                field="symbol"
                header="Name"
              ></Column>
            </DataTable>
          </div>
        </TabPanel>
      </TabView>
      <div style={{ position: "absolute", top: 10, right: 10 }}>
        {" "}
        <SearchInput
          onChange={(e) => searchScript(e.target.value)}
          type="text"
          placeholder="Search script"
        />
      </div>
    </div>
  );
}
