import React from "react";
import styled from "styled-components";
import TopbarMost from "../Common/TopbarMost";
import { NewTheme } from "../Theme/Theme";
import LiveTrade from "./LiveTrade/LiveTrade";
import Php from "../../Backend/Php";
import LiveUsers from "./LiveUsers/LiveUsers";
import RightHomePanel from "./LiveUsers/LiveUsers";
import { surveyPlaysound } from "../Notification/SoundUtility";
import NumberFormatIn from "../Common/NumberFormatIn";

const php = new Php();
const height = window.innerHeight;
const totalHeight = (height / 100) * 95;

export default function Home() {
  const [dashboardData, setDashboardData] = React.useState([]);
  const [userActiveCard, setUserActiveCard] = React.useState(true);
  const [userSqOff, setUserSqOff] = React.useState(true);

  React.useEffect(() => {
    loadDashboard();
  }, []);

  const loadDashboard = () => {
    let data = {
      sr: localStorage.getItem("server"),
      jwt: localStorage.getItem("token"),
      b_id: localStorage.getItem("brokersId"),
    };

    php.load_dashboard(data).then((r) => {
      if (r.error == "False") {
        setDashboardData(r.dashboard);
      } else {
        alert(r.message);
      }
    });
  };
  const topworkui = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 20,
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12 }}>Clients</div>
        <div style={{ fontSize: 18, fontWeight: "bold" }}>
          {dashboardData?.users}
        </div>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12 }}>Daily Turnover</div>
        <div
          style={{
            fontSize: 18,
            fontWeight: "bold",
            color:
              dashboardData?.daily_volume >= 0
                ? NewTheme.greencolor
                : NewTheme.redcolor,
          }}
        >
          <NumberFormatIn
            value={parseFloat(dashboardData?.daily_volume).toFixed(0)}
          />
        </div>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12 }}>Weekly TurnOver</div>
        <div
          style={{
            fontSize: 18,
            fontWeight: "bold",
            color:
              dashboardData?.weekly_volume >= 0
                ? NewTheme.greencolor
                : NewTheme.redcolor,
          }}
        >
          <NumberFormatIn
            value={parseFloat(dashboardData?.weekly_volume).toFixed(0)}
          />
        </div>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12 }}>Daily P/L</div>
        <div
          style={{
            fontSize: 18,
            fontWeight: "bold",
            color:
              dashboardData?.daily_profit_loss >= 0
                ? NewTheme.greencolor
                : NewTheme.redcolor,
          }}
        >
          <NumberFormatIn
            value={parseFloat(dashboardData?.daily_profit_loss).toFixed(2)}
          />
        </div>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12 }}>Weekly P/L</div>
        <div
          style={{
            fontSize: 18,
            fontWeight: "bold",
            color:
              dashboardData?.weekly_profit_loss >= 0
                ? NewTheme.greencolor
                : NewTheme.redcolor,
          }}
        >
          <NumberFormatIn
            value={parseFloat(dashboardData?.weekly_profit_loss).toFixed(2)}
          />
        </div>
      </div>
    </div>
  );
  return (
    <>
      <TopbarMost
        name={
          localStorage.getItem("server").toUpperCase() +
          " HOME | " +
          localStorage.getItem("brokersUsername")?.toUpperCase()
        }
        cmp={topworkui}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: totalHeight,
          padding: 10,
        }}
      >
        <div style={{ flex: 5 }}>
          <LiveTrade height={height - 70} />
        </div>
        {/* <div
          style={{
            flex: 1,
            marginLeft: 5,
            // height: "100%",
            // borderStyle: "solid",
            display: "flex",
            height: totalHeight - 60,
            flex: userActiveCard ? 1 : userSqOff ? 1 : 0,
            flexDirection: "column",
            gap: 10,
            // backgroundColor: NewTheme.lightmain,
          }}
        >
          {userActiveCard ? <RightHomePanel height={height - 70} /> : null}
        </div> */}
      </div>
    </>
  );
}
