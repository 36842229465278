import React from "react";
import { useImperativeHandle } from "react";
import { forwardRef } from "react";
import { useState } from "react";
import NumberFormatIn from "../../Common/NumberFormatIn";

const VolumeCard = forwardRef((props, ref) => {
  const [buy, setBuy] = useState(0);
  const [sell, setSell] = useState(0);
  useImperativeHandle(ref, () => ({
    calculatevolume(id) {
      var buy = 0;
      var sell = 0;
      id.map((i, t) => {
        if (i.buy_sell == 0) {
          var volume = parseFloat(i.qty) * parseFloat(i.rate);
          if (i.segment > 2) {
            volume = volume * i.dollor_rate;
          }
          buy = parseFloat(buy) + parseFloat(volume);
        } else {
          var volume = parseFloat(i.qty) * parseFloat(i.rate);
          if (i.segment > 2) {
            volume = volume * i.dollor_rate;
          }
          sell = parseFloat(sell) + parseFloat(volume);
        }
      });
      setBuy(buy);
      setSell(sell);
    },
  }));
  return (
    <>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12 }}>Buy volume</div>
        <div
          style={{
            fontWeight: "bold",
            fontSize: 16,
          }}
        >
          <div style={{ fontWeight: "bold" }}>
            <NumberFormatIn value={parseFloat(buy).toFixed(0)} />
          </div>
        </div>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12 }}>Sell volume</div>
        <div
          style={{
            fontWeight: "bold",
            fontSize: 16,
          }}
        >
          <div style={{ fontWeight: "bold" }}>
            <NumberFormatIn value={parseFloat(sell).toFixed(0)} />
          </div>
        </div>
      </div>
    </>
  );
});

export default VolumeCard;
