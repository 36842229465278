import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";

import * as ExcelJS from "exceljs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment/moment";
import { FilterMatchMode } from "primereact/api";
import Php from "../../Backend/Php";
import TopbarMost from "../Common/TopbarMost";
import { useNotification } from "../Notification/NotificationContext";
import { NewTheme } from "../Theme/Theme";
import Filter from "./Filter/Filter";
import VolumeModal from "./VolumeModal/VolumeModal";
import { InputText } from "primereact/inputtext";
import NumberFormatIn from "../Common/NumberFormatIn";
const php = new Php();

const height = window.innerHeight;

export default function Transaction() {
  const [transactionData, setTransactionData] = useState([]);
  const [transactionDataFilter, setTransactionDataFilter] = useState([]);

  const [usersFilter, setUsersFilter] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);

  const [totalProfitLoss, setProfitLoss] = useState(0);
  const [totalBrokerage, setTotalBrokerage] = useState(0);
  const [totalVolume, setTotalVolume] = useState(0);

  const [futVolume, setFutVolume] = useState(0);
  const [mcxVolume, setMcxVolume] = useState(0);
  const [mcxLot, setMcxLot] = useState(0);
  const [foVolume, setFoVolume] = useState(0);
  const [forexVolume, setForexVolume] = useState(0);
  const [cryptoVolume, setCryptoVolume] = useState(0);
  const [stocksVolume, setStocksVolume] = useState(0);

  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);

  const [date, setDate] = useState([new Date(), new Date()]);

  const [filters, setFilters] = useState({
    symbol: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });
  const [filterSymbol, setFilterSymbol] = React.useState([]);
  const [filterUser, setFilterUser] = React.useState([]);

  useEffect(() => {
    load_transactions();
  }, []);

  const load_transactions = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        b_id: localStorage.getItem("brokersId"),
        from_date:
          e == "clear"
            ? moment(new Date()).format("YYYY-MM-DD 23:59:59")
            : moment(date[0]).format("YYYY-MM-DD 00:00:00"),
        to_date:
          e == "clear"
            ? moment(new Date()).format("YYYY-MM-DD 23:59:59")
            : moment(date[1]).format("YYYY-MM-DD 23:59:59"),
        c_id:
          selectedUser?.id == undefined || e == "clear" ? 0 : selectedUser?.id,
      };

      php.load_transactions(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          let futVolumeAmt = 0;
          let mcxVolumeAmt = 0;
          let mcxVolumeLot = 0;
          let foVolumeAmt = 0;
          let forexVolumeAmt = 0;
          let cryptoVolumeAmt = 0;
          let stocksVolumeAmt = 0;

          let scripts = r.transactions.map((i, t) => {
            if (i.script_type == "fut") {
              futVolumeAmt += parseFloat(i.volume);
            } else if (i.script_type == "mcx" && parseFloat(i.brokerage) > 1) {
              var mcx = parseFloat(i.brokerage_amt) / parseFloat(i.brokerage);
              mcxVolumeLot += parseFloat(mcx);
            } else if (i.script_type == "mcx" && parseFloat(i.brokerage) < 1) {
              var mcx = parseFloat(i.brokerage_amt) / parseFloat(i.brokerage);
              mcxVolumeAmt += parseFloat(mcx);
            } else if (i.script_type == "fo") {
              var fo = parseFloat(i.brokerage_amt) / parseFloat(i.brokerage);
              foVolumeAmt += parseFloat(fo);
            } else if (i.script_type == "forex") {
              var forex = parseFloat(i.brokerage_amt) / parseFloat(i.brokerage);
              forexVolumeAmt += parseFloat(forex);
            } else if (i.script_type == "crypto") {
              var crypto =
                parseFloat(i.brokerage_amt) / parseFloat(i.brokerage);
              foVolumeAmt += parseFloat(crypto);
            } else if (i.script_type == "stocks") {
              var stocks =
                parseFloat(i.brokerage_amt) / parseFloat(i.brokerage);
              foVolumeAmt += parseFloat(stocks);
            }
          });

          setTotalBrokerage(parseFloat(r.brokerage).toFixed(2));
          setTotalVolume(parseFloat(r.volume).toFixed(0));
          setProfitLoss(parseFloat(r.profit_loss).toFixed(3));

          setFutVolume(futVolumeAmt);
          setMcxVolume(mcxVolumeAmt);
          setMcxLot(mcxVolumeLot);
          setFoVolume(foVolumeAmt);
          setForexVolume(forexVolumeAmt);
          setCryptoVolume(cryptoVolumeAmt);
          setStocksVolume(stocksVolumeAmt);

          setUsersFilter(r.users);
          setTransactionDataFilter(r.transactions);
          setTransactionData(r.transactions);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const cols = [
    { dataKey: "username", title: "Username" },
    { dataKey: "symbol", title: "Symbol" },
    { dataKey: "qty", title: "Qty" },
    { dataKey: "buy_Sell", title: "B/S" },
    { dataKey: "rate", title: "Rate" },
    { dataKey: "profit_loss", title: "P/L" },
    { dataKey: "brokerage_amt", title: "Brokerage" },
    { dataKey: "volume", title: "Volume" },
    { dataKey: "deposit", title: "Deposit" },
    { dataKey: "trade_msg", title: "Details" },
    { dataKey: "date_created", title: "Date" },
  ];

  const exportPdf = () => {
    // Your column and data definitions (replace these with your actual data)
    const doc = new jsPDF({
      orientation: "landscape",
    });
    const additionalCols = ["Overview", "", ""];

    var additionalData = [
      [1, "Profit/Loss", totalProfitLoss],
      [2, "Brokerage", totalBrokerage],
      [3, "Volume", totalVolume],
      [4, "Fut Volume", futVolume],
      [5, "Option Lot Wise", foVolume + " Lot"],
      [6, "Mcx Lot Wise", mcxLot + " Lot"],
    ];

    if (mcxVolume > 0) {
      additionalData.push([7, "Mcx Volume Wise", mcxVolume]);
    }

    doc.autoTable({
      head: [additionalCols],
      body: additionalData,
    });
    // Create a new jsPDF instance with landscape orientation

    // Add content to the PDF using autoTable
    doc.autoTable(cols, transactionData);

    var pdfName =
      selectedUser?.username != undefined
        ? selectedUser?.username + " - Transaction"
        : localStorage.getItem("server")?.toUpperCase() + " - Transaction";
    // Save or display the PDF

    doc.save(pdfName + ".pdf");
  };

  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState("top");

  const show = (position) => {
    setPosition("top");
    setVisible(true);
  };

  const exportExcel = () => {
    // Create a new Excel workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("data");

    // Specify the actual columns you want to export
    const colsToExport = [
      "username",
      "symbol",
      "qty",
      "buy_sell",
      "position_rate",
      "rate",
      "profit_loss",
      "brokerage",
      "volume",
      "deposit",
      "trade_msg",
      "date_created",
    ];

    // Specify the display names for the headers
    const headerDisplayNames = [
      "User Name",
      "Symbol",
      "Quantity",
      "Buy/Sell",
      "Position Rate",
      "Rate",
      "Profit/Loss",
      "Brokerage",
      "Volume",
      "Deposit",
      "Trade Message",
      "Date Created",
    ];

    // Add a summary with key-value pairs at the top
    worksheet.addRow(["Summary Key 1", "Summary Value 1"]);
    worksheet.addRow(["Summary Key 2", "Summary Value 2"]);

    // Add empty rows between summary and data
    for (let i = 0; i < 2; i++) {
      worksheet.addRow([]);
    }

    // Add column headers to the worksheet with display names
    worksheet.addRow(headerDisplayNames);

    // Extract only the desired columns from transactionData
    const filteredData = transactionData.map((item) => {
      return colsToExport.reduce((acc, col) => {
        if (col === "buy_sell") {
          acc[col] = item[col] == 0 ? "Buy" : "Sell";
        } else {
          acc[col] = item[col];
        }
        return acc;
      }, {});
    });

    // Add the filtered data to the worksheet
    filteredData.forEach((data) => {
      worksheet.addRow(Object.values(data));
    });

    // Set column width to 16 for all columns
    worksheet.columns.forEach((column) => {
      column.width = 16;
    });

    // Apply color to font based on the "profit_loss" condition
    const colIndex = colsToExport.indexOf("profit_loss");

    worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
      const cellValue = row.getCell(colIndex + 1).value; // Excel columns are 1-based

      if (cellValue !== null && rowNumber > 4) {
        const color = cellValue > 0 ? "00FF00" : "FF0000"; // Green for positive, Red for negative

        row.getCell(colIndex + 1).font = {
          color: { argb: color },
        };

        // Convert profit_loss to number and set number format
        row.getCell(colIndex + 1).value =
          rowNumber > 5 ? Number(cellValue) : cellValue;
        row.getCell(colIndex + 1).numFmt =
          rowNumber > 5 ? "#,##0.00" : undefined; // Adjust the number format as needed
      }

      // Add a footer below profit_loss, volume, brokerage
      if (rowNumber === filteredData.length + 6) {
        // Assuming 6 empty rows were added between data and footer
        worksheet.addRow(["Footer Below Profit/Loss"]);
        worksheet.addRow(["Footer Below Volume"]);
        worksheet.addRow(["Footer Below Brokerage"]);
      }
    });

    // Add empty rows between data and footer
    for (let i = 0; i < 2; i++) {
      worksheet.addRow([]);
    }

    var fileName =
      selectedUser?.username != undefined
        ? selectedUser?.username + " - Transaction"
        : localStorage.getItem("server")?.toUpperCase() + " - Transaction";

    // Create Excel file and trigger download
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAsExcelFile(buffer, fileName);
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const [filtersGlobal, setFiltersGlobal] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const value = filtersGlobal["global"] ? filtersGlobal["global"].value : "";
  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filtersGlobal };

    _filters["global"].value = value;

    setFilters(_filters);
  };

  const filterbar = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 20,
        fontWeight: "bold",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 30,
            flexDirection: "column",
          }}
        >
          <div
            style={{
              fontSize: 12,
            }}
          >
            Profitloss
          </div>
          <div
            style={{
              fontSize: 16,
              marginTop: 3,
              color:
                totalProfitLoss >= 0 ? NewTheme.greencolor : NewTheme.redcolor,
            }}
          >
            <NumberFormatIn value={parseFloat(totalProfitLoss).toFixed(2)} />
          </div>
        </div>
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 30,
            flexDirection: "column",
          }}
        >
          <div style={{ fontSize: 12 }}>Brokerage</div>
          <div
            style={{ fontSize: 16, color: NewTheme.greencolor, marginTop: 3 }}
          >
            <NumberFormatIn value={totalBrokerage} />
          </div>
        </div>
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 30,
            flexDirection: "column",
          }}
          onClick={() => show()}
        >
          <div style={{ fontSize: 12 }}>Volume</div>
          <div
            style={{
              fontSize: 16,
              marginTop: 3,
            }}
          >
            <NumberFormatIn value={totalVolume} />
          </div>
        </div>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 20,
        }}
      >
        <InputText
          style={{ width: 140 }}
          type="search"
          value={value || ""}
          onChange={(e) => onGlobalFilterChange(e)}
          placeholder="Search"
        />
      </div>
      <Filter
        selectedUser={selectedUser}
        usersFilter={usersFilter}
        setSelectedUser={(e) => setSelectedUser(e)}
        date={date}
        setDate={(e) => setDate(e)}
        clearFilter={() => load_transactions("clear")}
        load_transactions={load_transactions}
      />
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Button
          type="button"
          label="PDF"
          severity="warning"
          style={{
            height: 25,
            paddingLeft: 10,
            paddingRight: 10,
            fontSize: 12,
          }}
          onClick={exportPdf}
          data-pr-tooltip="PDF"
        />
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Button
          type="button"
          label="XLS"
          severity="primary"
          style={{
            height: 25,
            paddingLeft: 10,
            paddingRight: 10,
            fontSize: 12,
          }}
          onClick={exportExcel}
          data-pr-tooltip="EXCEL"
        />
      </div>
    </div>
  );

  return (
    <div
      className="card"
      style={{
        // background: "black",
        // padding: "0.5rem",
        paddingTop: 0,
        // borderRadius: "10px",
        // marginBottom: "1rem",
        overflow: "hidden",
      }}
    >
      <TopbarMost background={"#ffffff"} name="Transactions" cmp={filterbar} />

      <div id={"stick"} style={{ position: "relative" }}>
        <DataTable
          removableSort
          stripedRows
          showGridlines
          scrollable
          scrollHeight={height - 120}
          size="small"
          // filterDisplay="row"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          value={transactionData}
          style={{ fontWeight: "bold", fontSize: 14 }}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          globalFilterFields={["username", "symbol"]}
          paginator
          rows={15}
          filters={filters}
          onFilter={(e) => setFilters(e.filters)}
          rowsPerPageOptions={[10, 15, 30, 50, 100]}
        >
          <Column
            field="username"
            showFilterMenu={false}
            header="Clients"
            sortable
            filter
          />
          <Column
            field="symbol"
            showFilterMenu={false}
            header="Symbols"
            sortable
            filter
            body={(rowData) => (
              <>
                <div
                  style={{
                    color: NewTheme.MainColor,
                  }}
                >
                  {rowData.symbol}
                </div>
                <span style={{ color: "lightgray" }}>
                  {moment(rowData.expiry_date).format("DD-MMM")}
                </span>
              </>
            )}
          />
          <Column
            filter
            field="qty"
            showFilterMenu={false}
            header="Qty"
            body={(rowData) => (
              <div
                style={{
                  color:
                    rowData.buy_sell == 0
                      ? NewTheme.greencolor
                      : NewTheme.redcolor,
                }}
              >
                {rowData.qty}
              </div>
            )}
          />
          <Column
            filter
            field="buy_sell"
            showFilterMenu={false}
            header="B/S"
            body={(rowData) => (
              <div
                style={{
                  color:
                    rowData.buy_sell == 0
                      ? NewTheme.greencolor
                      : NewTheme.redcolor,
                }}
              >
                {rowData.buy_sell == 0 ? "Buy" : "Sell"}
              </div>
            )}
          />
          <Column
            filter
            field="position_rate"
            showFilterMenu={false}
            header="Position Rate"
            body={(rowData) => <>{parseFloat(rowData.position_rate)}</>}
          />
          <Column
            field="rate"
            body={(rowData) => <>{parseFloat(rowData.rate)}</>}
            filter
            showFilterMenu={false}
            header="Rate"
          />
          <Column
            filter
            field="profit_loss"
            showFilterMenu={false}
            header="P/L"
            sortable
            body={(rowData) => (
              <div
                style={{
                  color:
                    rowData.profit_loss >= 0
                      ? NewTheme.greencolor
                      : NewTheme.redcolor,
                }}
              >
                <NumberFormatIn
                  value={parseFloat(rowData.profit_loss).toFixed(0)}
                />
              </div>
            )}
          />

          <Column
            field="brokerage_amt"
            body={(rowData) => (
              <>
                <div>
                  <NumberFormatIn value={rowData.brokerage_amt} />
                </div>
              </>
            )}
            showFilterMenu={false}
            header="Brokerage"
          />

          <Column field="volume" showFilterMenu={false} header="Volume " />

          <Column
            field="deposit"
            filter
            sortable
            showFilterMenu={false}
            header="Deposit "
            body={(rowData) => (
              <span>
                <NumberFormatIn value={rowData.deposit} />
              </span>
            )}
          />

          <Column
            field="trade_msg"
            filter
            showFilterMenu={false}
            header="Details"
          />
          <Column
            filter
            field="date_created"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header="Date/Time"
            body={(rowData) => (
              <div>
                {moment(rowData.date_created).format("DD-MMM HH:mm:ss")}
              </div>
            )}
            sortable
          />
        </DataTable>
      </div>

      {visible ? (
        <VolumeModal
          key={new Date()}
          setVisible={() => setVisible(false)}
          position={position}
          visible={visible}
          futVolume={futVolume}
          mcxVolume={mcxVolume}
          foVolume={foVolume}
          mcxLot={mcxLot}
          forexVolume={forexVolume}
          cryptoVolume={cryptoVolume}
          stocksVolume={stocksVolume}
        />
      ) : null}
    </div>
  );
}
