import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useSidebar } from "../Sidebar/SidebarContext";
import { NewTheme } from "../Theme/Theme";

export default function TopbarMost(props) {
  const { toggleSidebar } = useSidebar();
  return (
    <div
      style={{
        height: props.height ? props.height : 55,
        display: "flex",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: "lightgray",
        background: props.background ? props.background : NewTheme.lightmain,
      }}
    >
      {props.noSidebar || props.onlyHeader ? null : (
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: 50,
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
            onClick={toggleSidebar}
          >
            <FontAwesomeIcon
              style={{ height: 20, width: 20, color: NewTheme.MainColor }}
              icon={faBars}
              size="lg"
            />
          </div>
          <div
            style={{
              marginLeft: 5,
              fontWeight: "bold",
              fontSize: 18,
              textAlign: "left",
              flex: 1,
              color: NewTheme.MainColor,
            }}
          >
            {props.name}
          </div>
        </div>
      )}
      {props.onlyHeader ? (
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginLeft: 5,
              fontWeight: "bold",
              fontSize: 16,
              textAlign: "left",
              flex: 1,
              color: NewTheme.MainColor,
            }}
          >
            {props.name}
          </div>
        </div>
      ) : null}
      <div style={{ flex: 3 }}>{props.cmp}</div>
    </div>
  );
}
